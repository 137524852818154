

















































































import Vue from "vue";

import TaskNewsAside from "@/components/TaskNewsAside.vue";
import NotificationsAside from "@/components/NotificationsAside.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Header",
  props: ["activeIndex"],
  components: {
    "task-news-aside": TaskNewsAside,
    "notifications-aside": NotificationsAside
  },
  data() {
    return {
      avatar: "",
      expand: true,
      showLogBox: false,
      taskNewsAsideOpen: false,
      notificationsAsideOpen: false
    };
  },
  computed: {
    ...mapGetters("auth", ["me"])
  },
  mounted() {
    this.$store.dispatch("auth/findMe").then(() => {
      const me = this.$store.getters["auth/me"];
      if (me.name) this.avatar = me.name[0].toUpperCase();
      else this.avatar = me.email[0].toUpperCase();
    });

    this.expand = localStorage.getItem("sidebar") == "false" ? false : true;
  },
  methods: {
    toggleSidebar() {
      this.expand = !this.expand;
      localStorage.setItem("sidebar", this.expand.toString());
    },
    makeLogout() {
      this.logout().then(() => {
        window.location.href = "/";
      });
    },
    showTaskNewsAside(value: boolean) {
      this.taskNewsAsideOpen = value;
    },
    ...mapActions("auth", ["logout"])
  }
});
