























































import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "DeviationsEdit",
  props: ["id"],
  components: {
    "b-header": Header,
    "blue-top": BlueTop
  },
  data() {
    return {
      isNew: false,
      is_queen: process.env.VUE_APP_IS_QUEEN,
      form: {
        woman_budget: 0,
        man_budget: 0,
        start_date: "",
        end_date: "",
        client: null,
        is_archivied: false
      },
      rules: {
        client: [{ required: true, message: "Campo obbligatorio" }],
        start_date: [{ required: true, message: "Campo obbligatorio" }],
        end_date: [{ required: true, message: "Campo obbligatorio" }],
        man_budget: [{ required: true, message: "Campo obbligatorio" }],
        woman_budget: [{ required: true, message: "Campo obbligatorio" }]
      }
    };
  },
  computed: {
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"])
  },
  async created() {
    await this.$store.dispatch("clients/getClientsList");
    await this.$store.dispatch("auth/findMe");
    if (this.me && this.me.role == 1) {
      await this.$store.dispatch("users/getUsersList");
    }
    if (this.$props.id != "nuovo") {
      await this.findDeviation(this.id)
        .then(response => {
          if (response.status == 200) {
            this.$set(this.$data, "form", response.data);
            this.form.client = response.data.client.id;
            this.form.start_date = response.data.start_date;
            this.form.end_date = response.data.end_date;
            this.form.man_budget = response.data.man_budget;
            this.form.woman_budget = response.data.woman_budget;
          } else {
            window.location.href = "/scostamenti";
          }
        })
        .catch(() => {
          window.location.href = "/scostamenti";
        });
    } else {
      this.isNew = true;
    }
  },
  methods: {
    resetDialog() {
      window.location.href = "/scostamenti";
    },
    async onSubmit() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        this.form.start_date = moment(this.form.start_date).format(
          "YYYY-MM-DD"
        );
        this.form.end_date = moment(this.form.end_date).format("YYYY-MM-DD");

        interface OrderForm {
          man_budget: number;
          woman_budget: number;
          start_date: string;
          end_date: string;
          is_archivied: boolean;
        }

        const data: OrderForm = {
          ...this.form
        };

        if (this.isNew) {
          this.newDeviation(data).then(async response => {
            if (response.id) {
              window.location.href = "/scostamenti";
            }
          });
        } else {
          this.editDeviation(data).then(async response => {
            if (response.id) {
              window.location.href = "/scostamenti";
            }
          });
        }
      } else {
        return false;
      }
    },
    ...mapActions("deviations", [
      "newDeviation",
      "editDeviation",
      "findDeviation"
    ])
  }
});
