




































import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Deviations",
  components: {
    "b-header": Header,
    "blue-top": BlueTop
  },
  created() {
    this.$store.dispatch("deviations/getDeviationsList");
  },
  computed: {
    ...mapGetters("deviations", { tableData: "deviationsList" })
  },
  methods: {
    editRow(id: number) {
      window.location.href = "/scostamenti/" + id;
    }
  }
});
